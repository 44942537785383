@font-face {
  font-family: "Jost-Light";
  src: url("/assets/fonts/cuenta/Jost-Light.ttf");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Jost-Regular";
  src: url("/assets/fonts/cuenta/Jost-Regular.ttf");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Jost-Medium";
  src: url("/assets/fonts/cuenta/Jost-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost-Medium" !important;
}

strong {
  font-family: "Jost-Medium" !important;
}

input:not([type="checkbox"]),
.font-regular,
.fw-normal,
.font-normal,
.fw-regular {
  font-family: "Jost-Regular" !important;
}

small,
.small,
.font-light,
.fw-light {
  font-family: "Jost-Light" !important;
}

.xsmall {
  font-family: "Jost-Regular" !important;
  font-size: 0.8em !important;
}
